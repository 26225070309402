<template>
  <LayoutVendor>
    <template v-slot:title>MATERIALS AND SUPPLIES</template>
    <template v-slot:topbar>
      <!-- Vendor Add Product Btn -->
      <div v-if="isAddAllowed" class="d-flex justify-content-end">
        <!-- Desktop Button -->
        <AppBtn
          class="text-uppercase ml-3 d-none d-md-inline-block"
          @click="openAddTypes"
        >
          Add new product
        </AppBtn>
        <!-- Mobile Add Button -->
        <AppIcon
          name="add_filled"
          class="ml-2 text-primary d-md-none"
          @click="openAddTypes"
        ></AppIcon>

        <!-- Draft Button -->
        <!-- <AppBtn
          text
          class="ml-3 d-none d-md-inline-block text-uppercase text-black"
          @click="openAddTypes"
        >
          Drafts
        </AppBtn> -->

        <!-- Select a type of product to add (not yet done) -->
        <AppModal v-model="addTypeModal" width="800px">
          <div class="p-4">
            <div class="d-flex justify-content-end">
              <AppIcon
                hoverable
                name="close"
                @click="addTypeModal = false"
              ></AppIcon>
            </div>
            <h5 class="text-md-center mt-n4 mt-md-3">
              SELECT TYPE OF PRODUCT TO BE UPLOADED
            </h5>

            <!-- Actial Typs -->
            <div class="mt-4 row">
              <div
                v-for="(type, index) in addTypes"
                :key="`add-type-${index}`"
                class="col-12 col-md-4"
                @click="chooseOption(index)"
              >
                <div
                  class="
                    add-product-item
                    pt-md-5
                    pb-md-2
                    p-1
                    mb-3
                    d-flex
                    flex-md-column
                    align-items-center
                    justify-content-md-center
                    text-uppercase
                  "
                  :class="{ active: index === addIndex }"
                >
                  <img :src="type.img" alt="" />
                  <h5 class="ml-3 ml-md-0 mt-4">{{ type.name }}</h5>
                </div>
              </div>
            </div>

            <!-- confirm btn -->
            <div
              class="
                mt-md-5
                d-flex
                flex-column flex-md-row
                justify-content-center
              "
            >
              <AppBtn
                class="rounded-sm"
                :disabled="addIndex === false"
                @click="confirm"
              >
                CONFIRM
              </AppBtn>
            </div>
          </div>
        </AppModal>
      </div>
    </template>
    <ProductList
      vendor
      :gotoUrl="isEditAllowed ? 'product/:id/edit' : 'product/:id'"
    ></ProductList>
  </LayoutVendor>
</template>

<script>
import ProductList from '@/components/Partial/Products/ProductList.vue';
import LayoutVendor from '@/components/Partial/Layouts/LayoutVendor/LayoutVendor.vue';
import AppBtn from '@/shared/elements/AppBtn.vue';
import AppIcon from '@/shared/elements/AppIcon.vue';
import AppModal from '@/shared/elements/AppModal.vue';
import { group_names } from '@/shared/constants/PersonaConstants';
import { isRoleAllowed } from '@/router/AuthorizationGate';

export default {
  name: 'VendorProductList',

  components: { ProductList, LayoutVendor, AppBtn, AppIcon, AppModal },

  data() {
    return {
      addTypeModal: false,
      addIndex: false,
      addTypes: [
        {
          name: 'Materials & Supplies',
          img: require('@/assets/products/svg/Material.svg'),
          route: { name: 'ProductAdd' },
        },
        {
          name: 'Equipment',
          img: require('@/assets/products/svg/Equipment.svg'),
          route: { name: 'ProductAdd' },
        },
        {
          name: 'Services',
          img: require('@/assets/products/svg/Services.svg'),
          route: { name: 'ProductAdd' },
        },
      ],
    };
  },

  computed: {
    isAddAllowed() {
      const { owner, admin, vendor } = group_names;
      return isRoleAllowed([owner, admin, vendor]);
    },
    isEditAllowed() {
      const { owner, admin, vendor } = group_names;
      return isRoleAllowed([owner, admin, vendor]);
    },
  },

  methods: {
    openAddTypes() {
      this.addTypeModal = true;
    },
    chooseOption(index) {
      this.addIndex = index;
    },
    confirm() {
      const gotoSrc = this.addTypes[this.addIndex].route;
      this.addTypeModal = false;
      this.$router.push(gotoSrc);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/theme/_colors';
@import '@/scss/theme/_border';

.add-product-item {
  img {
    width: 100px;
    height: 100px;
  }
  @extend .bg-gray-light-4;
  @extend .border-gray-light-4;
  @extend .border-1;
  @extend .border-solid;
  &:hover,
  &.active {
    @extend .bg-primary-light-5;
    @extend .border-primary;
  }
}
</style>
