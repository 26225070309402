<template>
  <div
    class="
      sidebar-submenu
      d-flex
      flex-column
      border border-gray-light-2
      bg-gray-light-4
    "
  >
    <div class="sidebar-submenu-header ml-3 align-items-center">
      <h2>CATEGORIES</h2>
    </div>

    <div class="sidebar-submenu-items overflow-auto">
      <ProductCategoryMenuItem
        v-for="(item, index) in categories"
        :key="`category-item-${index}`"
        :text="item.category"
        :items="item.product_count ? Math.max(item.products_count, item.product_count) : item.products_count"
        :active="innerVal === item.id"
        @click="$emit('input', item.id)"
      ></ProductCategoryMenuItem>
    </div>
  </div>
</template>

<script>
import _appDefaultInput from '@/shared/mixins/_appDefaultInput';
import ProductCategoryMenuItem from './ProductCategoryMenuItem.vue';
export default {
  name: 'ProductCategoryMenu',

  components: { ProductCategoryMenuItem },

  mixins: [_appDefaultInput],

  props: {
    primaryRoute: { type: String, default: '/' },
    categories: { type: Array, default: () => [] },
  },
};
</script>

<style lang="scss" scoped></style>
