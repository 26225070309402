<template>
  <div
    class="
      sidebar-submenu-item
      p-4
      d-flex
      font-weight-normal
      text-body-3
      d-flex
      align-items-center
    "
    :class="{ 'sidebar-submenu-item-active': active }"
    v-on="$listeners"
  >
    <!-- icon -->
    <AppIcon name="caret_right"></AppIcon>
    <!-- text -->
    <span v-if="text.length > 0">{{ text }}</span>
    <slot v-else></slot>
    <span class="ml-1">({{ items }})</span>
  </div>
</template>

<script>
import AppIcon from '@/shared/elements/AppIcon.vue';
export default {
  name: 'ProductCategoryMenuItem',

  components: { AppIcon },

  props: {
    route: { type: String, default: '' },
    text: { type: String, default: '' },
    items: { type: Number, default: 0 },
    icon: { type: String, default: '' },
    active: { type: Boolean, default: false },
  },

  methods: {
    gotoRoute() {
      if (!this.active) {
        this.$router.push(this.route);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/theme/_colors';
.sidebar-submenu-item {
  cursor: pointer;
  transition: background-color 0.2s;
  &-active,
  &-active:hover {
    @extend .text-primary;
    @extend .bg-white;
  }

  &:hover {
    @extend .bg-white;
  }
}
</style>
