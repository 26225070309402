<template>
  <div class="h-100 oveflow-hidden product-list d-flex flex-column flex-md-row">
    <!-- Category Menu -->
    <div
      class="product-list-category-menu overflow-auto flex-shrink-0 d-md-block"
    >
      <div
        class="p-1 pt-2 bg-gray-light-4 border d-md-none"
        @click="showMobileCategory = true"
      >
        <span>Showing</span>
        <span class="ml-2 text-primary">
          {{
            categories.find((value) => value.id === category).category || 'All'
          }}
        </span>
      </div>
      <div
        class="product-list-category-menu-list h-100 d-md-block"
        :class="{ 'd-none': !showMobileCategory }"
      >
        <AppIcon
          name="close"
          size="32"
          class="product-list-category-menu-list-close m-1 d-md-none"
          @click="showMobileCategory = false"
        ></AppIcon>
        <ProductCategoryMenu
          v-model="category"
          :categories="categories"
          class="h-100 w-100"
        ></ProductCategoryMenu>
      </div>
    </div>
    <!-- Content -->
    <div class="product-list-content m-0 flex-grow-1 h-100 overflow-auto">
      <!-- Sort/Filter Actions -->
      <div
        class="
          product-list-filters
          p-2 p-md-3
          sticky-top
          d-flex
          justify-content-between
          bg-white
        "
      >
        <!-- Sort -->
        <AppDropdown
          :items="sortItems"
          @itemClick="(sortItem) => (sort = sortItem)"
        >
          <template v-slot:dropdown-button>
            <AppBtn color="gray-light-3" class="p-1">
              <div class="d-flex align-items-center text-body-3">
                <AppIcon name="sort_descending"></AppIcon>
                <span class="ml-2 d-none d-md-block">Sort By:</span>
                <span class="ml-2 font-weight-normal">{{ sort.text }}</span>
              </div>
            </AppBtn>
          </template>
        </AppDropdown>
        <!-- Filter -->
        <AppDropdown right>
          <template v-slot:dropdown-button>
            <AppBtn color="gray-light-3" class="p-1">
              <div class="d-flex align-items-center text-body-3">
                <AppIcon name="settings_adjust"></AppIcon>
                <span class="ml-2 d-none d-md-block">Filter</span>
              </div>
            </AppBtn>
          </template>
          <template v-slot:dropdown-menu>
            <div v-if="false" class="dropdown-item d-flex align-items-center">
              <AppInputCheckbox hideError></AppInputCheckbox>
              <span>With open purchase order</span>
            </div>
            <div class="dropdown-item d-flex align-items-center">
              <AppInputCheckbox
                v-model="filters.out_of_stock_only"
                hideError
              ></AppInputCheckbox>
              <span>Out of stock</span>
            </div>
          </template>
        </AppDropdown>
      </div>

      <!-- Product List -->
      <AppLoading v-if="loading" fillSpace class="p-5 w-100"></AppLoading>
      <div v-else class="row mt-5 p-3">
        <div
          v-for="(product, index) in products"
          :key="`product-item-${index}`"
          class="col-6 col-md-3 mb-5 mb-md-4"
        >
          <router-link
            :to="{
              name: vendor === true ? 'ProductEdit' : 'ProductView',
              params: { id: product.id },
            }"
            class="text-decoration-none text-black"
          >
            <ProductListCard
              :name="product.name"
              :price="product | displayPrice"
              :thumbnail="product | displayThumbnail"
              :stocks="vendor ? getProductStocks(product) : false"
            ></ProductListCard>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductCategoryMenu from '@/components/Partial/Products/CategoryMenu/ProductCategoryMenu.vue';
import ProductListCard from '@/components/Partial/Products/ProductListCard.vue';
import AppBtn from '@/shared/elements/AppBtn.vue';
import AppDropdown from '@/shared/elements/AppDropdown.vue';
import AppIcon from '@/shared/elements/AppIcon.vue';
import AppInputCheckbox from '@/shared/elements/AppInputCheckbox.vue';
import Products from '@/shared/api/Products';
import AppLoading from '@/shared/elements/AppLoading.vue';
import _strings from '@/shared/mixins/_strings.js';
import Vue from 'vue';

export default {
  name: 'ProductList',

  props: {
    vendor: { type: Boolean, default: false },
    gotoUrl: { type: String, default: '' },
  },

  mixins: [_strings],

  components: {
    ProductCategoryMenu,
    AppDropdown,
    AppBtn,
    AppIcon,
    AppInputCheckbox,
    ProductListCard,
    AppLoading,
  },

  data() {
    // static sortItems for sort dropdown
    const sortItems = [
      { text: 'Recent Uploads', value: 'sort_by_newest' },
      { text: 'Alphabetical A-Z', value: 'sort_by_name_asc' },
      { text: ' Alphabetical Z-A', value: 'sort_by_name_desc' },
    ];

    return {
      categories: [
        {
          category: 'All',
          id: false,
          items: 10,
          products_count: 0,
        },
      ],
      category: false,
      sortItems,
      sort: sortItems[0],
      filters: {
        out_of_stock_only: false,
      },
      products: [],
      loading: false,
      showMobileCategory: false,
      // temporaryplaceholder for thumbnaol
      thumbnail: require('@/assets/mock_assets/product/lg-wood-laminate-walnut.png'),
    };
  },

  watch: {
    category() {
      this.showMobileCategory = false;
      this.sortProducts();
    },
    sort() {
      this.sortProducts();
    },
    filters: {
      handler() {
        this.sortProducts();
      },
      deep: true,
    },
  },

  filters: {
    displayPrice(product) {
      const price_start = Vue.options.filters.toMoney(product.price_start);
      const price_end = Vue.options.filters.toMoney(product.price_end);
      const price_fixed = Vue.options.filters.toMoney(product.price_fixed);

      if (price_start > 0 && price_start !== price_end) {
        return `$${price_start} - $${price_end}`;
      } else if (
        price_fixed === 0 ||
        price_fixed === '' ||
        price_fixed == null
      ) {
        return `$${price_start}`;
      } else {
        return `$${price_fixed}`;
      }
    },
    displayThumbnail(product) {
      const files = product.files;
      const primaryImageIndex = files.findIndex(
        (value) => value.type === 'primary_image'
      );
      if (files.length === 0) {
        return null;
      } else if (primaryImageIndex === -1) {
        return files[0].filename;
      } else {
        return files[primaryImageIndex].filename;
      }
    },
  },

  methods: {
    async sortProducts() {
      const sort_by = this.sort.value;
      const filters = this.filters;
      const category = this.category;

      await this.getProducts(category, sort_by, filters);
    },
    async getCategories() {
      let categories = (
        await Products.getCategories({ params: { with_uncategorized: true } })
      ).data;
      categories = categories.filter(
        (value) => value.products_count > 0 || value.product_count > 0
      );

      this.categories = [...this.categories, ...categories];
    },
    async getProducts(category, sort_by = 'sort_by_newest', filters = {}) {
      // sort by
      const params = { [sort_by]: true };
      const query = { ...this.$route.query };

      // filters
      for (const [filterName, val] of Object.entries(filters)) {
        if (val) {
          params[filterName] = true;
        }
      }

      // category filter
      if (category) {
        params.category_id = category;
        query.category_id = filters.category;
      } else {
        delete query.category_id;
      }

      if (this.vendor) {
        params.with_inactive = true;
      } else {
        params.status = 'live';
        params.with_inactive = false;
      }

      if (this.$route.query && this.$route.query.search) {
        params.search = this.$route.query.search;
      }

      this.loading = true;
      this.products = (await Products.getProducts({ params })).data;

      this.loading = false;

      if (Object.keys(query).length != Object.keys({...this.$route.query}).length) {
        this.$router.replace({ name: 'ProductList', query });
      }
    },
    getProductStocks(item) {
      if (item.variants && item.variants.length > 0) {
        return item.variants.reduce((sum, variant) => sum + variant.stocks, 0);
      }

      return parseInt(item.stocks);
    },
  },

  async mounted() {
    await this.getProducts();
    this.categories[0].products_count = this.products.length;
    await this.getCategories();
  },
};
</script>

<style lang="scss" scoped>
@use "sass:math";
@import '@/scss/theme/_breakpoints';

$menu-width: (math.div(100vw, 12) * 2);
.product-list {
  min-height: 100%;
  &-category-menu {
    &-list {
      // display: none;
      width: $menu-width;
      @include media-md('below') {
        width: 100%;
        position: absolute;
        z-index: 10;
      }

      &-close {
        position: absolute;
        right: 0;
      }
    }

    &-header {
      height: 86px;
    }
  }

  &-filters {
    z-index: 1;
  }

  &-content {
    overflow-x: hidden !important;
  }
}
</style>
